.Container{
    position: relative;
    top: 10px;
    left: 12%;
    width: 80%;
    height: 700px;
}

.job-title{
    font-family: Optima;
    font-size: 30px;
    color: rgb(0, 132, 255);
}

.job-times{
    font-family: Optima;
    font-size: 20px;
    color: rgb(43, 131, 214);
}

.job-description{
    font-family: Optima;
    font-size: 20px;
    color: rgb(8, 101, 189);
}
