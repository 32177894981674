figure {
    position: relative;
}

.BPost {
    position: relative;
}

.Title {
    font-size: 30px;
    font-family: Optima;
    font-weight: 500;
    color: rgb(50, 110, 221)
}

.Date {
    font-size: 25px;
    font-family: Optima;
    font-weight: 300;
    font-style: italic;
    color: rgb(50, 110, 221);
    display: inline;
}

.Tag {
    font-size: 25px;
    font-family: Optima;
    font-weight: 300;
    font-style: italic;
    color: rgb(50, 144, 221);
    display: inline;
}

/* These are for the contents of each blog post */
.BPost div {
    font-size: 20px;
    font-family: Optima;
    font-weight: 200;
}

/* Hyperlinks */
.BPost div a {
    color: blue;
    text-decoration: underline;
}

/* Bullet points */
ul li {
    list-style-type: disc;
    margin-left: 20px;
}

.BPost img {
    margin-left: auto;
    margin-right: auto;
    width: 300px;
    border-radius: 10%;
}

.BPost .Bigger {
    width: 500px;
}

.left {
    float: left;
}

.right {
    float: right;
}

figure figcaption {
    font-family: Optima;
    font-style: italic;
    font-size: 20px;
    color: rgb(73, 141, 197);
    caption-side: bottom;
    text-align: center;
}

.Content {
    line-height: 32px;
}

h1 {
    line-height: 10px;
}

.DropdownButton {
    cursor: pointer;
    background-color: rgb(134, 192, 240);
    border: none;
    color: white;
    font-size: 20px;
    font-weight: 600;
    font-family: Optima;
    padding: 10px;
}

.DropdownButton:hover {
    background-color: rgb(12, 46, 170);
}

.TagsContainer {
    position: relative;
    display: inline-block;
}

.Dropdown {
    display: none;
    position: absolute;
    background-color: rgb(134, 192, 240);
    color: white;
    z-index: 1;
    border: 1px solid blue;
}

.Dropdown p {
    display: block;
    padding-left: 10px;
    padding-right: 10px;
    margin: 0;
    /* Make elements hug one another vertically */
    font-size: 20px;
}

.Dropdown p:hover {
    background-color: rgb(12, 46, 170);
}

.ShowDropdown {
    display: block;
}

.FilterStatus {
    color: rgb(134, 192, 240);
    font-size: 20px;
    font-weight: 600;
    padding-left: 20px;
}

.popup {
    display: inline;
}

.nopopup {
    display: none;
}

.comment-box {
    border-radius: 5px;
    border: none;
    padding: 10px;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    color: #333;
    width: 100%;
    /* set the width to 100% to make it fill the screen */
}

.post-button {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin-top: 10px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.post-button:hover {
    background-color: #3e8e41;
}


@media (max-width: 480px) {
    .comment-box {
        font-size: 14px;
        padding: 5px;
        margin: 5px;
        width: 100%;
        /* set the width to 100% to make it fill the screen */
        max-width: none;
        /* remove the maximum width */
        overflow-x: scroll;
        /* use a horizontal scroll instead of auto */
        /* rest of the styles */
    }
}

.Comments {
    color: rgb(50, 110, 221);
}
