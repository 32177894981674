.Container{
    position:relative;
    height: 600px;
}

.Picture{
    position: absolute;
    margin: 0 auto;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden; /*Hide what falls outside of the div*/
    width: 380px; /* Only let the div grow in height when we hover over the image (by constraining the width) */
    border-radius: 20%;
}

.pfp{
    width: 380px;
}

.Caption{
    position: absolute;
    top: 70%;
    left: 0%;
    right: 0%;
    text-align: center;
}

.Caption p{
    font-family: Optima;
    font-size: 30px;
    color: rgb(54, 136, 243);
    line-height: 12px;
}

.EasterEgg{
    display: none;
    position: relative;
    top: -20px;
}

.pfp:hover + .EasterEgg{
    display: block;
    text-align: center;
    color: rgb(54, 136, 243);
    padding-left: 20px;
    padding-right: 20px;
}

/* Links */
p a, p a:visited{
    text-decoration: underline;
    color: rgb(54, 136, 243);
}

p a:hover{
    color:rgb(149, 189, 241);
}

.IconLink{
    height: 50px;
}
