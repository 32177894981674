.website{
    font-family: Optima;
}

hr{
    /*
    background-color:lightblue;
    color:lightblue;
    border-color: lightblue;*/
    /*box-shadow: 0px 1px 3px rgb(38, 179, 225);
    */
    border-top: 0px; /*Get rid of default top border that disrupts color flow */
}

.Logo{
    color:rgba(28, 116, 239, 0.996);
    font-weight:900;
    font-size: 150%;
    position: relative;
    padding-left: 10%;
    /*border: 2px solid orange;*/ /*FOR DEBUGGING*/
    width: 30%;
}

.Wrapper{
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    background-color: #FCFCFC;
}

.header-placeholder {
    visibility: hidden;
    height: 80px;
}

.NavContainer{
    position: relative;
    padding-right: 10%;
    top: 15px;
    float: right;
}

.NavButton{
    position: relative;
    font-size: 250%;
    font-weight: 600;
    padding-left: 30px;
    padding-right: 30px;
    color: #0077C2;
    top: 8px;
    text-decoration: none;
}

.NavButton:hover{
    color: lightskyblue;
}

@viewport {
    width: device-width ;
    zoom: 1.0 ;
}

@media screen and (max-width: 1000px){
    .Logo{
        position: relative;
        top: 10%;
        font-size: 120%;
    }
    .NavContainer{
        top: 0.5vh;
    }
    .NavButton{
        position: relative;
        font-size: 100%;
    }
    .Caption p, h1, h2{ /* This Caption class is for the homepage captions */
        line-height: 10px;
        font-size: 20px;
    }
    h1, h2{
        line-height: 30px;
    }
    .Caption, .LinksContainer a{ /* This Caption class is for the project captions. I know, confusing. Bad naming choice lmao */
        font-size: 20px;
        line-height: 25px;
    }
    .Caption{
        position: relative;
        width: 130%;
        left: -25%;
    }
    .Thumbnail{
        max-width: 400px;
        max-height: 500px;
        width: auto;
        height: auto;
        position: relative;
        right: 30%;
    }
}

body {
    background: #FCFCFC;
}

@media (prefers-color-scheme: dark) {
    body {
      background: #0A192F; /*Midnight blue, thanks ChatGPT*/
      color: rgb(255, 255, 255);
    }
    .Wrapper{
        background-color: #0A192F;
    }
    .NavButton{
        color: #F8F8F8; /*Smokey gray, recommended by ChatGPT*/
    }
}
