figure{
    text-align: center;
}

.Thumbnail{
    height: 500px;
}

.Caption{
    position: relative;
    font-size: 25px;
    padding-left: 10%;
    padding-right: 10%;
    font-style: normal;
    line-height: 38px;
}

.LinksContainer, .LinksContainer a{
    text-align: center;
    font-family: Optima;
    font-size: 25px;
    color:rgb(10, 115, 201);
    text-decoration: none;
}

.LinksContainer a:hover{
    color:rgb(107, 180, 240);
}
